import { useState, useEffect, useRef } from "react";
import "./App.css";
import axios from "axios";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import ReactToPrint from "react-to-print";

function Client(params) {
  let { ticketId } = useParams();

  const [tik, setTik] = useState({});
  const [getError, setGetError] = useState("");
  const [loading, setLoading] = useState(false);

  const printRef = useRef();

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/client/" + ticketId)
      .then(function (res) {
        if (res.data.status) {
          console.log(res.data.data[0]);
          setLoading(false);
          setGetError("");
          setTik(res.data.data[0]);
        } else {
          setGetError(
            "oops! something went wrong please refresh the page and try again"
          );
          setLoading(false);
        }
      })
      .catch(function (error) {
        setGetError(
          "oops! something went wrong please refresh the page and try again"
        );
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading ? (
        <div className="mt-4 p-4 text-center">
          <Spinner animation="border" size="sm" role="status"></Spinner>
        </div>
      ) : (
        ""
      )}

      {getError.length > 0 ? (
        <div className="mt-4 p-4 text-center">
          <p>{getError}</p>
        </div>
      ) : (
        ""
      )}
      <div className="c-main container" hidden={loading} ref={printRef}>
        <div className="ver c-header">
          <div className="row m-0">
            <img
              src="https://markcaseyseo.com/assets/img/main/logo.png"
              alt=""
              className=""
            />
            <div className="texter">
              <h3>Mark Casey Seo</h3>
              <p>Progress report</p>
            </div>
          </div>
          <p>{}</p>
        </div>

        <div className="c-inner bg-white p-4">
          <div className="row m-0">
            <div className="mr-auto">
              <div
                className=""
                hidden={tik.asin ? (tik.asin.length > 0 ? false : true) : true}
              >
                <h4 className="label">Asin</h4>
                <h4 className="label-content">{tik.asin}</h4>
              </div>
              <div
                className="mt-4"
                hidden={
                  tik.invoice_number
                    ? tik.invoice_number.length > 0
                      ? false
                      : true
                    : true
                }
              >
                <h4 className="label">Invoice number</h4>
                <h4 className="label-content">{tik.invoice_number}</h4>
              </div>
            </div>

            <div className="mr-auto">
              <div
                hidden={tik.start ? (tik.start.length > 0 ? false : true) : true}
              >
                <h4 className="label">Start Date</h4>
                <h4 className="label-content">{tik.start}</h4>
              </div>
              <div
                className="mt-4"
                hidden={
                  tik.total_orders
                    ? tik.total_orders.length > 0
                      ? false
                      : true
                    : true
                }
              >
                <h4 className="label">Total Orders</h4>
                <h4 className="label-content">{tik.total_orders}</h4>
              </div>
            </div>

            <div className="mr-auto">
              <div hidden={tik.end ? (tik.end.length > 0 ? false : true) : true}>
                <h4 className="label">Est end date</h4>
                <h4 className="label-content">{tik.end}</h4>
              </div>
              <div
                className="mt-4"
                hidden={
                  tik.email ? (tik.email.length > 0 ? false : true) : true
                }
              >
                <h4 className="label">Email</h4>
                <h4 className="label-content">{tik.email}</h4>
              </div>
            </div>
          </div>

          <h4 className="mt-5 mb-0 font-weight-bold">Order Numbers</h4>
          <hr />
          <div className="c-numbers">
            {tik.numbers
              ? JSON.parse(tik.numbers).map((num, id) => <p key={id}>{num}</p>)
              : ""}
          </div>

          <div>
            {tik.numbers ? (
              <ProgressBar
                className="mt-4"
                style={{
                  height: "60px",
                  width: "100%",
                  background: "#e8ecf3",
                }}
                variant="success"
                animated={
                  JSON.parse(tik.numbers).length < tik.total_orders
                    ? true
                    : false
                }
                now={(JSON.parse(tik.numbers).length / tik.total_orders) * 100}
                label={`${Math.round(
                  (100 * JSON.parse(tik.numbers).length) / tik.total_orders
                )}% Complete`}
              />
            ) : (
              ""
            )}
          </div>

          <p className="mt-5">{tik.notes}</p>
        </div>

        <div className="c-footer ver">
          <small>&copy; 2021 markcaseyseo.com</small>
          <ReactToPrint
            trigger={() => (
              <small className="pointer">
                <i className="fa fa-print"></i> Print
              </small>
            )}
            content={() => printRef.current}
          />
        </div>
      </div>
    </div>
  );
}

export { Client };
