import { useState, useEffect } from "react";
import "./App.css";
import axios from "axios";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";



function gencode() {
  let a =
    "tid" +
    String(Math.floor(Math.random() * 1000)) +
    String(Math.floor(Math.random() * 300)) +
    String(Math.floor(Math.random() * 500));
  return a;
}

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [token, setToken] = useState("");

  const [tickets, setTickets] = useState([]);

  const [tikId, setTikId] = useState("");
  const [name, setName] = useState("");
  const [invNum, setInvNum] = useState("");
  const [asin, setAsin] = useState("");
  const [orders, setOrders] = useState("");
  const [email, setEmail] = useState("")
  const [team, setTeam] = useState("");
  const [notes, setNotes] = useState("")
  const [numbers, setNumbers] = useState([]);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [archived, setArchived] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState("")


  const [inView, setInView] = useState("main"); // main, archived

  /* add number */
  const [saving, setSaving] = useState(false);

  const [isNew, setIsNew] = useState(false);


  const [loadingTs, setLoadingTs] = useState(false)



  useEffect(() => {
    if (localStorage.getItem("session_token")) {
      axios
        .post("/api/loggedin", {
          session_token: localStorage.getItem("session_token"),
        })
        .then(function (res) {
          if (res.data.status) {
            setLoggedIn(true);
            setToken(localStorage.getItem("session_token"));
            fetchTickets(localStorage.getItem("session_token"));
          } else {
            setLoggedIn(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          fetchTickets(localStorage.getItem("session_token"));
        });
    }
  }, []);

  function fetchTickets(session_token) {
    axios
      .post("/api/tickets", {
        token: session_token,
      })
      .then(function (res) {
        if (res.data.status) {
          setLoggedIn(true);
          setTickets(res.data.data);
        } else {
          setLoggedIn(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoggedIn(false);
      });
  }

  function auth() {
    axios
      .post("/api/login", { username: username, password: password })
      .then(function (res) {
        console.log(res);
        if (res.data.status) {
          //store token to localStorage
          localStorage.setItem("session_token", res.data.session_token);
          setLoggedIn(true);
          setToken(res.data.session_token);
          fetchTickets(res.data.session_token);
        } else {
          setLoggedIn(false);
          setLoginError(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  

  function editTicket(ticketId) {
    let tik = tickets.filter((ticket) => ticket.tid === ticketId)[0];
    setTikId(tik.tid);
    setName(tik.name);
    setInvNum(tik.invoice_number);
    setAsin(tik.asin);
    setOrders(tik.total_orders);
    setEmail(tik.email);
    setTeam(tik.team);
    setNotes(tik.notes)
    setNumbers(JSON.parse(tik.numbers));
    setStart(tik.start);
    setEnd(tik.end);
    setArchived(JSON.parse(tik.archived));
    setShowModal(true);
  }

  function newTicket() {
    setTikId("");
    setName("");
    setInvNum("");
    setAsin("");
    setOrders(60);
    setEmail("");
    setTeam("SC");
    setNotes("")
    setNumbers([]);
    setStart("");
    setEnd("");
    setArchived("");
    setShowModal(true);
    setIsNew(true);
  }

  function closeModal() {
    setShowModal(false);
    setTikId("");
    setName("");
    setInvNum("");
    setAsin("");
    setOrders("");
    setTeam("");
    setNotes("")
    setNumbers([]);
    setStart("");
    setEnd("");
    setArchived("");
  }

  function toggleArchive(ticketId, archived) {
    axios
      .post("/api/restore", {
        token: token,
        ticket_id: ticketId,
        to: archived === "true" ? "false" : "true",
      })
      .then(function (res) {
        console.log(res.data);
        if (res.data.status) {
          fetchTickets(localStorage.getItem("session_token"));
        } else {
          setLoggedIn(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoggedIn(false);
      });
  }

  function delTicket() {
    
    axios
      .post("/api/delete", {
        token: token,
        ticket_id: tikId,
      })
      .then(function (res) {
        console.log(res.data);
        if (res.data.status) {
          fetchTickets(localStorage.getItem("session_token"));
          setShowModal(false)
        } else {
          setLoggedIn(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoggedIn(false);
      });
  }

  function addNumber(nums) {
    let arr = nums.split("\n").filter((each) => each.length > 0);
    setNumbers(arr);
  }

  function delNumber(num) {
    let arr = [...numbers];
    if (arr.indexOf(num) > -1) {
      arr.splice(arr.indexOf(num), 1);
    }
    setNumbers(arr);
  }

  function save() {
    setSaving(true);
    if (isNew === false) {
      axios
        .post("/api/save", {
          token: token,
          tid: tikId,
          name: name,
          invNum: invNum,
          asin: asin,
          orders: orders,
          email: email,
          team: team,
          notes: notes,
          numbers: numbers,
          start: start,
          end: end,
          archived: JSON.stringify(archived),
        })
        .then(function (res) {
          console.log(res.data);
          if (res.data.status) {
            fetchTickets(token);
            setSaving(false);
          } else {
            setLoggedIn(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setLoggedIn(false);
        });
    } else {
      axios
        .post("/api/create", {
          token: token,
          tid: gencode(),
          name: name,
          invNum: invNum,
          asin: asin,
          orders: orders,
          email: email,
          team: team,
          notes: notes,
          numbers: numbers,
          start: start,
          end: end,
          archived: "false",
        })
        .then(function (res) {
          console.log(res.data);
          if (res.data.status) {
            fetchTickets(token);
            setSaving(false);
            setShowModal(false);
            setIsNew(false);
          } else {
            setLoggedIn(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setLoggedIn(false);
        });
    }
  }
  
  function filterTickets(){
    setLoadingTs(true)
    axios
      .post("/api/tickets", {
        token: token,
      })
      .then(function (res) {
        console.log(res.data);
        if (res.data.status) {
          let arr = res.data.data
          let filtered = arr.filter((each)=>{
            return each.name.toLowerCase().includes(search.toLowerCase())
          })
          setTickets(filtered)
          setLoadingTs(false)
        } else {
          setLoggedIn(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoggedIn(false);
      });
  }

  useEffect(() => {
    if(search.length > 0){
      filterTickets()
    } else {
      fetchTickets(token);
    }
  }, [search]);

  return (
    <div className="main container-fluid">
      {loggedIn ? (
        <div>
          <div className="col-lg-10 ml-auto mr-auto">
            <div className="ver">
              <h1 className="mt-4 title mr-3">
                {inView === "archived" ? "Archived" : "Tickets"}
              </h1>
              <div className="ver">
                <button
                  className="search-btn"
                  onClick={() => {
                    newTicket();
                  }}
                >
                  <i className="fa fa-plus"></i>
                </button>
                <button
                  className={
                    inView === "archived" ? "search-btn active" : "search-btn"
                  }
                  onClick={() => {
                    setInView(inView === "archived" ? "main" : "archived");
                  }}
                >
                  {inView === "archived" ? (
                    <i className="fa fa-arrow-left"></i>
                  ) : (
                    <i className="fa fa-archive"></i>
                  )}
                </button>
                <button
                  className="search-btn "
                  onClick={() => {
                    setShowSearch(!showSearch);
                  }}
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>

            <div className="ver search" hidden={showSearch ? false : true}>
              <button>
                <i className="fa fa-search"></i>
              </button>
              <input
                type="text"
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>

            <hr className="mt-2 mb-4" />

            <div className="tickets">
              <div className="col-lg-12 mb-3 text-center">
                {loadingTs ? (
                  <Spinner animation="border" size="sm" role="status"></Spinner>
                ) : (
                  ""
                )}
              </div>
              {inView === "main"
                ? tickets.map((ticket, id) => (
                    <div
                      key={id}
                      className="ticket"
                      hidden={ticket.archived === "false" ? false : true}
                    >
                      <div className="ver top">
                        <p className="t-title">{ticket.name}</p>
                        <p className="font-weight-bold mt-2 date">
                          {ticket.start} - {ticket.end}
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-lg-5 mb-2">
                          <p
                            className="font-weight-bold mb-0"
                            hidden={
                              ticket.invoice_number.length > 0 ? false : true
                            }
                          >
                            INV #:{" "}
                            <font className="t-light">
                              {ticket.invoice_number}
                            </font>
                          </p>
                          <p
                            className="font-weight-bold mb-0"
                            hidden={ticket.asin.length > 0 ? false : true}
                          >
                            Asin :{" "}
                            <font className="t-light">{ticket.asin}</font>
                          </p>
                          <p
                            className="font-weight-bold mb-0"
                            hidden={ticket.email.length > 0 ? false : true}
                          >
                            Email :{" "}
                            <font className="t-light">{ticket.email}</font>
                          </p>
                          <span className="badge badge-purple">
                            {ticket.team}
                          </span>
                        </div>

                        <div className="col-lg-7">
                          <ProgressBar
                            style={{
                              height: "60px",
                              width: "100%",
                              background: "#e8ecf3",
                            }}
                            variant="success"
                            animated={
                              JSON.parse(ticket.numbers).length <
                              ticket.total_orders
                                ? true
                                : false
                            }
                            now={
                              (JSON.parse(ticket.numbers).length /
                                ticket.total_orders) *
                              100
                            }
                            label={`${Math.round(
                              (100 * JSON.parse(ticket.numbers).length) /
                                ticket.total_orders
                            )}%`}
                          />
                          <div className="row mt-1">
                            <button
                              className="bubble-btns ml-auto mr-1"
                              onClick={() => {
                                toggleArchive(ticket.tid, ticket.archived);
                              }}
                            >
                              <i className="fa fa-archive"></i>
                            </button>
                            <button
                              className="bubble-btns mr-3"
                              onClick={() => {
                                editTicket(ticket.tid);
                              }}
                            >
                              Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : tickets.map((ticket, id) => (
                    <div
                      key={id}
                      className="ticket"
                      hidden={ticket.archived === "true" ? false : true}
                    >
                      <div className="ver top">
                        <p className="t-title">{ticket.name}</p>
                        <p className="font-weight-bold mt-2 date">
                          {ticket.start} - {ticket.end}
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 mb-2">
                          <p className="font-weight-bold mb-0">
                            INV #:{" "}
                            <font className="t-light">
                              {ticket.invoice_number}
                            </font>
                          </p>
                          <p className="font-weight-bold mb-0">
                            Asin :{" "}
                            <font className="t-light">{ticket.asin}</font>
                          </p>
                          <span className="badge badge-purple">
                            {ticket.team}
                          </span>
                        </div>

                        <div className="col-lg-8">
                          <ProgressBar
                            style={{
                              height: "60px",
                              width: "100%",
                              background: "#e8ecf3",
                            }}
                            variant="success"
                            animated
                            now={
                              (JSON.parse(ticket.numbers).length /
                                ticket.total_orders) *
                              100
                            }
                            label={`${Math.round(
                              (100 * JSON.parse(ticket.numbers).length) /
                                ticket.total_orders
                            )}%`}
                          />
                          <div className="row mt-1">
                            <button
                              className="bubble-btns mr-3 ml-auto"
                              onClick={() => {
                                toggleArchive(ticket.tid, ticket.archived);
                              }}
                            >
                              Restore
                            </button>
                            <button
                              className="bubble-btns mr-3"
                              onClick={() => {
                                editTicket(ticket.tid);
                              }}
                            >
                              Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>

          <Modal
            size="xl"
            show={showModal}
            backdrop={"static"}
            keyboard={false}
            backdropClassName="pop-bg"
            hidden={showModal ? false : true}
            contentClassName="pop-modal"
          >
            <Modal.Body
              className="pl-5 pr-5 pb-5"
              style={{ borderRadius: "30px" }}
            >
              <div className="inner">
                <Modal.Header className="pop-header ver">
                  <input
                    type="text"
                    Value={name}
                    placeholder="Enter ticket name"
                    className="title-input"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <div className="row m-0">
                    {tikId.length > 0 ? (
                      <a href={"/ticket/" + tikId} target="_blank">
                        <button className="btn btn-primary">
                          <i className="fa fa-share"></i>
                        </button>
                      </a>
                    ) : ""}

                    <button
                      className="btn btn-light ml-3"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </Modal.Header>

                <div className="pop-main">
                  <div className="ver pop-input">
                    <div className="inp-container">
                      <small>Start date</small>
                      <br />
                      <input
                        type="text"
                        placeholder="dd-mm-yyy"
                        value={start}
                        onChange={(e) => {
                          setStart(e.target.value);
                        }}
                      />
                    </div>

                    <div className="inp-container">
                      <small>Est end date</small>
                      <br />
                      <input
                        type="text"
                        placeholder="dd-mm-yyy"
                        value={end}
                        onChange={(e) => {
                          setEnd(e.target.value);
                        }}
                      />
                    </div>

                    <div className="inp-container">
                      <small>INV #</small>
                      <br />
                      <input
                        type="text"
                        placeholder="Invoice number"
                        value={invNum}
                        onChange={(e) => {
                          setInvNum(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="ver pop-input">
                    <div className="inp-container">
                      <small>Asin</small>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Asin"
                        value={asin}
                        onChange={(e) => {
                          setAsin(e.target.value);
                        }}
                      />
                    </div>

                    <div className="inp-container">
                      <small>Total orders needed</small>
                      <br />
                      <input
                        type="number"
                        placeholder=""
                        value="60"
                        value={orders}
                        onChange={(e) => {
                          setOrders(e.target.value);
                        }}
                      />
                    </div>

                    <div className="inp-container">
                      <small>Email</small>
                      <br />
                      <input
                        type="email"
                        placeholder="Enter email"
                        value="60"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>

                    <div className="inp-container">
                      <small>Team In charge</small>
                      <br />
                      <select
                        name="team"
                        value={team}
                        onChange={(e) => {
                          setTeam(e.target.value);
                        }}
                      >
                        {team.length > 0 ? (
                          <option value={team}>{team}</option>
                        ) : (
                          ""
                        )}
                        <option value="SC">SC</option>
                        <option value="NIKKI">NIKKI</option>
                        <option value="BSL">BSL</option>
                      </select>
                    </div>
                  </div>

                  <div className="order-numbers">
                    <div className="div ver mb-2 ">
                      <div className="">
                        <h5 className="font-weight-bold mb-0">
                          Order numbers{" "}
                        </h5>
                        <span
                          className="badge badge-success mt-0"
                          hidden={numbers.length <= 0 ? true : false}
                        >
                          {numbers.length} / {orders}
                        </span>
                      </div>
                    </div>
                    <div className="row mr-0">
                      <div className="add-number col-lg-5">
                        <small className="small">
                          Please make sure to enter all the numbers at once by
                          copy and pasting them form a single excel column or
                          press enter after entering each number
                        </small>
                        <textarea
                          cols="30"
                          rows="3"
                          onChange={(e) => {
                            addNumber(e.target.value);
                          }}
                          className="form-control mb-3 mt-1"
                          placeholder="1
2
3"
                        ></textarea>
                      </div>
                      <div className="numbers  col-lg-7">
                        {numbers.map((num, id) => (
                          <button
                            key={id}
                            onClick={() => {
                              delNumber(num);
                            }}
                          >
                            {num}
                          </button>
                        ))}
                      </div>

                      <textarea
                        cols="30"
                        rows="5"
                        onChange={(e) => {
                          setNotes(e.target.value);
                        }}
                        value={notes}
                        className="form-control mb-3 mt-1"
                        placeholder="Notes"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="pop-footer ver mt-3">
                  {tikId.length > 0 ? (
                    <button
                      className="action-danger col-lg-1 mr-3  font-weight-bold"
                      onClick={() => {
                        delTicket();
                      }}
                    >
                      Delete
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    className="action-btn font-weight-bold"
                    onClick={() => {
                      save();
                    }}
                    disabled={name.length > 0 ? false : true}
                  >
                    {saving ? (
                      <Spinner
                        animation="border"
                        size="sm"
                        role="status"
                      ></Spinner>
                    ) : (
                      <font>
                        <i className="fa fa-save"></i> Save
                      </font>
                    )}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <div className="login">
          <div className="inner">
            <h2 className="title-min m-0 mb-3">
              <i className="fa fa-users"></i> Team{" "}
            </h2>

            {loginError ? (
              <p className="text-danger mb-2">
                Your username or password is incorrect please try again
              </p>
            ) : (
              ""
            )}
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />

            <button className="action-btn" onClick={() => auth()}>
              Sign In
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
